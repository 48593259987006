import * as apis from "@/api/land";
import { $emit } from "@/utils/gogocodeTransfer";
export default {
  name: "Order",
  props: {
    content: Object,
    tid: String,
    dia: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      ruleOt: [],
      ruleList: [],
      radio1: [],
      user_zone1: "",
      user_zone2: "",
      user_zone3: "",
      params: {
        user_name: "",
        // 姓名
        user_address: "",
        // 地址
        user_mobile: "",
        // 手机号
        user_input: {},
        // 输入框集合
        // user_mobileCode: '', // 验证码
        user_zone: "",
        // 地区
        user_zone_detail: {},
        // 地址集合
        payment_method: 1,
        // 支付方式
        payment_scene: "",
        // 支付场景
        price: 0,
        // 价格
        qty: 1,
        // 购买数量
        total: "",
        // 总额
        remark: "",
        // 备注
        attribute: {} // 规格选项集合
      },

      price: "0.00",
      // 价格
      otPrice: "0.00",
      // 原价
      zoneList1: [],
      zoneList2: [],
      zoneList3: [],
      formId: "",
      // 订单ID
      formNo: "",
      openId: "",
      unionId: "",
      timer: null,
      query_times: 0,
      expired: false,
      dialogVisible: false,
      qrcode: "",
      loading: false,
      feedbackText: "",
      elLoading: null,
      jumpUrl: "",
      paymentData: {},
      code: ''
    };
  },
  watch: {
    radio1: {
      deep: true,
      handler(newV) {
        this.$nextTick(() => {
          let list = this.content.order.todolist.find(item => item.name === "private");
          if (list) {
            // 判断是否每种规格都有选
            if (list.data.length === newV.length) {
              let todolist = this.content.order.todolist;
              let tableBodyList = this.content.order.tableColumnList.tableBodyList;
              let attrName = this.content.order.attrName;
              // 查询对应的规格参数价格
              tableBodyList.forEach(item => {
                todolist.forEach(elm => {
                  if (elm.name === "private") {
                    if (attrName.length === 1) {
                      if (item[attrName[0]].value === this.params.attribute[elm.data[0].id]) {
                        this.params.price = Number(item.price);
                        this.price = Number(item.price).toFixed(2);
                        this.otPrice = Number(item.otPrice).toFixed(2);
                        // 如果有数量则同步计算总金额
                        if (this.params.qty) {
                          this.calculated(this.params.qty);
                        }
                      }
                    }
                    if (attrName.length === 2) {
                      if (item[attrName[0]].value === this.params.attribute[elm.data[0].id] && item[attrName[1]].value === this.params.attribute[elm.data[1].id]) {
                        this.params.price = Number(item.price);
                        this.price = Number(item.price).toFixed(2);
                        this.otPrice = Number(item.otPrice).toFixed(2);
                        // 如果有数量则同步计算总金额
                        if (this.params.qty) {
                          this.calculated(this.params.qty);
                        }
                      }
                    }
                    if (attrName.length === 3) {
                      if (item[attrName[0]].value === this.params.attribute[elm.data[0].id] && item[attrName[1]].value === this.params.attribute[elm.data[1].id] && item[attrName[2]].value === this.params.attribute[elm.data[2].id]) {
                        this.params.price = Number(item.price);
                        this.price = Number(item.price).toFixed(2);
                        this.otPrice = Number(item.otPrice).toFixed(2);
                        // 如果有数量则同步计算总金额
                        if (this.params.qty) {
                          this.calculated(this.params.qty);
                        }
                      }
                    }
                  }
                });
              });
            }
          }
        });
      }
    },
    "params.qty": {
      deep: true,
      handler(newV) {
        this.calculated(newV);
      }
    },
    dia: {
      deep: true,
      handler(newV, oldV) {
        if (newV === 1 && oldV === 3) {
          this.checkPayment();
        }
      },
      immediate: true
    }
  },
  async created() {
    // 获取城市列表
    this.getcorpList();
    // 判断网页设备
    await this.browserInfo();
    // 初始化金额
    this.content.order.todolist.forEach(item => {
      if (item.name === "price") {
        this.otPrice = item.value;
        this.price = item.data;
        this.params.price = item.data;
        this.params.total = item.data;
      }
      if (item.name === "check_payment") {
        this.params.payment_method = item.item;
      }
    });
    if (this.content.jsonData.feedback === 1) {
      this.feedbackText = this.content.jsonData.feedbackText;
    }
    if (this.content.jsonData.feedback === 2) {
      this.jumpUrl = this.content.jsonData.jumpUrl;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (this.params.payment_scene == 3) {
      // 微信浏览器调用授权
      if (code && state) {
        // 存在code就不用调用授权
        this.code = code;
      } else {
        // 微信浏览器调用授权
        await this.getCodeUrlFun();
      }
    }
  },
  methods: {
    // 判断网页设备
    browserInfo() {
      let ua = navigator.userAgent || "";
      let browser = {
        // 微信小程序
        isWeiXinMiniProgram: window.__wxjs_environment === "miniprogram" || /miniProgram/i.test(ua),
        // 微信浏览器
        isWeiXin: ua.toLowerCase().match(/MicroMessenger/i) == "micromessenger",
        // H5浏览器，非微信浏览器
        isH5: ua.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
        // PC
        isPC: !/Android|webOS|iPhone|iPad|iPod|SymbianOS|BlackBerry|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(ua)
      };
      if (browser.isWeiXinMiniProgram) {
        // 微信小程序
        this.params.payment_scene = 4;
      } else if (browser.isWeiXin) {
        // 微信浏览器
        this.params.payment_scene = 3;
      } else if (browser.isH5) {
        // H5
        this.params.payment_scene = 1;
      } else if (browser.isPC) {
        // PC
        this.params.payment_scene = 2;
      } else {
        // 其它
        return 0;
      }
    },
    // 规格选项改变
    radioChange(val, row) {
      row.value.forEach(ele => {
        if (ele.label === val) {
          this.params.attribute[row.id] = ele.value;
        }
      });
      let index = this.ruleList.indexOf("private");
      let key = this.ruleOt.indexOf("private");
      let attrName = this.content.order.attrName;
      let rule = attrName.length === Object.keys(this.params.attribute).length;
      if (!rule) {
        if (index === -1) this.ruleList.push("private");
        if (key === -1) this.ruleOt.push("private");
      } else {
        if (index !== -1) this.ruleList.splice(index, 1);
        if (key !== -1) this.ruleOt.splice(key, 1);
      }
    },
    // 获取城市列表
    getcorpList() {
      apis.getOptions({
        option: "zone"
      }).then(res => {
        const {
          data
        } = res;
        this.zoneList1 = data.zone;
      });
    },
    // 手机号校验
    checkRules(e, type) {
      const {
        value
      } = e.target;
      let index = this.ruleList.indexOf(type);
      let key = this.ruleOt.indexOf(type);
      switch (type) {
        case "user_mobile":
          let rule = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value);
          if (!rule) {
            if (index === -1) this.ruleList.push(type);
            if (key === -1) this.ruleOt.push(type);
          } else {
            if (index !== -1) this.ruleList.splice(index, 1);
            if (key !== -1) this.ruleOt.splice(key, 1);
          }
          break;
        default:
          // 永远执行不到这里
          break;
      }
    },
    // 地址改变1
    handleChange1(val) {
      this.zoneList1.forEach(item => {
        if (item.id === val) {
          this.user_zone2 = "";
          this.user_zone3 = "";
          this.zoneList2 = [];
          this.zoneList3 = [];
          this.params.user_zone_detail = {};
          if (item.children) {
            this.zoneList2 = item.children;
          }
          let zone1 = {
            id: item.id,
            name: item.name
          };
          this.params.user_zone_detail["zone1"] = zone1;
        }
      });
    },
    // 地址改变2
    handleChange2(val) {
      this.zoneList2.forEach(item => {
        if (item.id === val) {
          this.user_zone3 = "";
          this.zoneList3 = [];
          delete this.params.user_zone_detail["zone3"]; // 重新选择删除第三级城市选项
          if (item.children) {
            this.zoneList3 = item.children;
          }
          let zone2 = {
            id: item.id,
            name: item.name
          };
          this.params.user_zone_detail["zone2"] = zone2;
        }
      });
    },
    // 地址改变3
    handleChange3(val) {
      this.zoneList3.forEach(item => {
        if (item.id === val) {
          let zone3 = {
            id: item.id,
            name: item.name
          };
          this.params.user_zone_detail["zone3"] = zone3;
        }
      });
    },
    // 计算总金额
    calculated(val) {
      this.params.total = val * this.params.price;
    },
    async getCodeUrlFun() {
      const {
        data
      } = await apis.getAuthorizeUrl({
        redirect_url: window.location.href
      });
      //https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx645de505b124fa68&redirect_uri=https%3A%2F%2Fv2.henm.cn%2F&response_type=code&scope=snsapi_userinfo&state=52dd8cd8819fd8d95757768d64a86d4f&connect_redirect=1#wechat_redirect
      // const str = `appid=${data.corpid}&redirect_uri=${UrlEncode}&response_type=code&scope=snsapi_base&agentid=${data.agentid}&state=${corp_id}`;
      let a = document.createElement('a');
      // a.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' + str;
      a.href = data.redirectUrl;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    // 提交
    submit() {
      // 判断网页设备
      this.browserInfo();
      this.loading = true;
      // this.dia = 2;
      $emit(this, "diaChange", 2);
      if (this.params.payment_scene === 1) {
        // H5
        console.log("H5端支付");
        if (this.params.payment_method === 3 && this.params.price != "0") {
          this.params.payment_scene = 7;
        }
        this.submitTemplate();
      }
      if (this.params.payment_scene === 2) {
        // PC
        console.log("PC端支付");
        // 付款方式选择富友且单价不为0的情况下
        if (this.params.payment_method === 3 && this.params.price != "0") {
          this.params.payment_scene = 7;
        }
        this.submitTemplate();
      }
      if (this.params.payment_scene === 3) {
        // 微信浏览器
        console.log("微信浏览器支付");
        // 付款方式选择富友且单价不为0的情况下
        if (this.params.payment_method === 3 && this.params.price != "0") {
          this.params.payment_scene = 7;
        }
        this.submitTemplate();
        // this.loginWx()
      }

      if (this.params.payment_scene === 4) {
        // 微信小程序
        console.log("微信小程序支付");
        // 付款方式选择富友且单价不为0的情况下
        if (this.params.payment_method === 3 && this.params.price != "0") {
          this.params.payment_scene = 7;
        }
        this.submitTemplate();
      }
    },
    // 订单提交
    submitTemplate() {
      if (this.ruleOt.length !== 0 || this.ruleList.length !== 0) {
        // let list = JSON.parse(JSON.stringify(this.ruleOt));
        this.ruleList = JSON.parse(JSON.stringify(this.ruleOt));
        this.$message({
          message: "请规范填写内容",
          type: "error"
        });
        this.loading = false;
        return;
      }
      if (this.params.user_zone_detail["zone1"]) {
        this.params.user_zone = this.params.user_zone_detail["zone1"].name;
        if (this.params.user_zone_detail["zone2"]) {
          this.params.user_zone = this.params.user_zone_detail["zone1"].name + this.params.user_zone_detail["zone2"].name;
          if (this.params.user_zone_detail["zone3"]) {
            this.params.user_zone = this.params.user_zone_detail["zone1"].name + this.params.user_zone_detail["zone2"].name + this.params.user_zone_detail["zone3"].name;
          }
        }
      }
      let obj = {
        tid: this.tid,
        widget_template_id: this.content.jsonData.orderId,
        data: this.params
      };
      if (this.content.jsonData.feedback === 2) {
        obj["jump_params"] = this.content.jsonData.parameter;
      }
      apis.submitTemplate(obj).then(res => {
        if (this.params.payment_method == 1) {
          if (res.data.payment_data.length === 0) {
            this.successJump();
            return;
          }
          this.formId = res.data.form_id;
          this.formNo = res.data.form_no;
          this.openId = res.data.open_id;
          this.unionId = res.data.union_id;
          this.paymentData = res.data.payment_data;
          this.payment();
        } else if (this.params.payment_method == 2) {
          if (res.data.payment_data && res.data.payment_data.url) {
            var a = document.createElement('a');
            a.setAttribute('href', res.data.payment_data.url);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
        //获取配置信息
        else {
          if (!res.data.payment_data.url) {
            this.successJump();
            return;
          }
          this.formId = res.data.form_id;
          this.paymentData = res.data.payment_data;
          $emit(this, "paymentDataUrlChange", res.data.payment_data.url);
          this.payment();
        }
      }).catch(res => {
        this.loading = false;
        this.$message({
          message: res,
          type: "error"
        });
      });
    },
    // 提交成功后的跳转
    successJump() {
      // this.dia = 1;
      $emit(this, "diaChange", 1);
      this.loading = false;
      this.paymentData = {};
      if (this.content.jsonData.feedback === 1) {
        this.$message({
          message: this.feedbackText,
          type: "success"
        });
        this.paymentData = {};
        $emit(this, "paymentDataUrlChange", {});
      } else if (this.content.jsonData.feedback === 2) {
        this.$message({
          message: "订单提交成功，正在为您跳转",
          type: "success"
        });
        setTimeout(() => {
          window.location.href = this.jumpUrl;
        }, 2000);
      } else if (this.content.jsonData.feedback === 3) {
        //打开小程序
        $emit(this, 'openWechat', this.content.jsonData.appletlink, this.content.jsonData.appletUrl, this.content.jsonData.wholeApplet, this.content.jsonData.wholeAppletType);
      } else if (this.content.jsonData.feedback === 4) {
        $emit(this, 'jumpKf', this.content.jsonData.wechatKf);
      }
    },
    // 唤起支付
    payment() {
      // 订单提交反馈
      if (this.params.payment_method !== 3) {
        for (const key in this.paymentData) {
          if (key === "qr_code") {
            this.dialogVisible = true;
            this.qrcode = this.paymentData.qr_code;
          }
          if (key === "url") {}
          if (key === "config") {}
          setTimeout(() => {
            this.checkPayment();
          }, 1000);
        }
      } else {
        setTimeout(() => {
          // this.dia = 3;
          $emit(this, "diaChange", 3);
        }, 1000);
      }
    },
    // 取消支付
    dialogClose() {
      this.$confirm("确认取消支付?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        clearInterval(this.timer);
        this.dialogVisible = false;
      }).catch(() => {
        // catch error
        return;
      });
    },
    // 关闭唤起支付窗口
    // back() {
    //   this.dia = 1;
    //   $emit(this, "diaChange", 1);
    //   this.checkPayment();
    // },
    // 轮询检测是否支付
    checkPayment() {
      // this.dia = 3;
      // $emit(this, "diaChange", 3);
      try {
        clearInterval(this.timer);
      } catch (e) {}
      this.expired = false;
      this.query_times = 0;
      this.timer = setInterval(() => {
        this.query_times++;
        if (this.query_times > 60) {
          this.expired = true;
          this.loading = false;
          this.$message({
            message: "支付超时，请重新提交支付",
            type: "error"
          });
          clearInterval(this.timer);
          // this.dia = 1;
          // $emit(this, "diaChange", 1);
          this.paymentData = {};
          $emit(this, "paymentDataUrlChange", {});
          return;
        }
        apis.getPaymentStatus({
          form_id: this.formId
        }).then(res => {
          if (res.data.payment_status === 1) {
            clearInterval(this.timer);
            this.successJump();
          }
        });
      }, 3000);
    }
  }
};