import request from '@/utils/request'

//落地页详情(渲染)
export function landDetail(data) {
  return request({
    url: 'landing_page/detail',
    params: data,
  })
}

//小工具模版详情
export function widgetTemplate(data) {
  return request({
    url: 'landing_page/widget_template',
    params: data,
  })
}

//微信号码包的二维码(h5)
export function wechatqrcode(data) {
  return request({
    url: 'landing_page/wechat_qrcode',
    params: data,
  })
}

//微信号码包的二维码（小程序）
export function wechat_qrcode_trace(data) {
  return request({
    url: 'landing_page/wechat_qrcode_trace',
    params: data,
  })
}

//追踪-Web浏览
export function visit(data) {
  return request({
    url: 'landing_page/visit',
    data,
    method: 'POST',
  })
}

//生成小程序 URL SCHEME
export function generateScheme(data) {
  return request({
    url: 'landing_page/generate_scheme',
    data,
    method: 'POST',
  })
}

//生成小程序 URL SCHEME（公众号链接）
export function generateOfficial(data) {
  return request({
    url: 'landing_page/generate_official',
    data,
    method: 'POST',
  })
}

//落地页详情（预览）
export function preview(data) {
  return request({
    url: 'landing_page/preview',
    params: data,
  })
}

//追踪-其他
export function trace(data) {
  return request({
    url: 'landing_page/trace',
    data,
    method: 'POST',
  })
}

//获取逻辑问答模板详情
export function landingPageAnswerDetail(params) {
  return request({
    url: 'landing_page/answer/detail',
    params
  })
}

// 获取城市名
export function getOptions(data) {
  return request({
    url: "option",
    params: data
  })
}

// 提交订单
export function submitTemplate(data) {
  return request({
    url: "landing_page/widget_template",
    data,
    method: 'POST',
  })
}

// 获取支付状态
export function getPaymentStatus(data) {
  return request({
    url: "landing_page/widget_template/payment_status",
    params: data,
  })
}


//获取微信客服跳转地址
export function getWechatKfUrl(params) {
  return request({
    url: 'landing_page/wechat_kf_url',
    params
  })
}

//获取图片base64
export function getImage(params) {
  return request({
    url: 'landing_page/get_image',
    params
  })
}

// 微信浏览器环境授权获取code
export function getAuthorizeUrl(params) {
  return request({
    url: 'landing_page/getAuthorizeUrl',
    params
  })
}

