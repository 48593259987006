import { $on, $off, $once, $emit } from "@/utils/gogocodeTransfer";
import * as Vue from "vue";

// 接口
import * as apis from "@/api/land";
export default {
  props: {
    content: Object,
    pageType: Boolean,
    tid: String,
    source: String,
    landingPageId: Number,
    modelType: {
      type: String,
      default: "android"
    }
  },
  data() {
    return {
      img: "",
      baseimg: "",
      fontSize: "",
      screenWidth: document.body.clientWidth,
      cliw: "",
      clleft: "",
      cltop: "",
      imgsize: "",
      longTouch: false,
      showDialog: false
    };
  },
  created() {
    // 获取图片base64数据
    if (this.pageType) {
      // 小程序
      this.wechat_qrcode_trace();
    } else if (this.source) {
      // h5
      this.wechat_qrcode_trace();
      this.trace();
    } else {
      this.wechatqrcode();
    }
  },
  mounted() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 800) clientWidth = 800;
    let wsize = (clientWidth / 376).toFixed(2);
    this.imgsize = wsize * this.content.w;
    this.clleft = wsize * this.content.x;
    this.cltop = wsize * this.content.y;

    // 监听宽度变化，刷新页面
    window.onresize = () => {
      return (() => {
        location.reload();
      })();
    };
  },
  methods: {
    touchStart(e) {
      // 模拟长按
      clearTimeout(this.loop); // 再次清空定时器，防止重复注册定时器（会把点击事件也阻止掉）
      this.loop = setTimeout(() => {
        this.longPress();
      }, 1000);
    },
    touchend() {
      clearTimeout(this.loop); // 清空定时器，防止重复注册定时器
    },

    //真正长按后应该执行的内容
    longPress() {
      console.log('长按了');
      const parse = {
        tid: this.tid,
        step: "40"
      };
      apis.trace(parse).then(res => {});
    },
    wechatqrcode() {
      const parse = {
        wechat_package_id: this.content.wechat
      };
      apis.wechatqrcode(parse).then(res => {
        if (res.code == "200") {
          this.img = res.data.qr_code;
          $emit(this, "userwechatid", res.data.id);
        } else {
          $emit(this, "userwechatid");
        }
        // ios环境下的H5模式才执行图片合并
        if (!this.pageType && this.modelType === "ios") {
          this.getImage(res.data.qr_code);
        }
      });
    },
    wechat_qrcode_trace() {
      let parse;
      parse = {
        tid: this.tid,
        wechat_package_id: this.content.wechat,
        landing_page_id: this.landingPageId
      };
      apis.wechat_qrcode_trace(parse).then(res => {
        this.img = res.data.qr_code;
        if (!this.pageType && this.modelType === "ios") {
          this.getImage(res.data.qr_code);
        }
      });
    },
    trace() {
      const parse = {
        tid: this.tid,
        step: "31"
      };
      apis.trace(parse).then(res => {});
    },
    // 图片流转换，canvas合并图片，解决ios长按识别图片层级提高问题
    getImage(img) {
      apis.getImage({
        image: img
      }).then(res => {
        this.baseimg = res.data.base64;
        $emit(this, "handleCanvas");
      });
    }
  },
  emits: ["userwechatid", "handleCanvas"]
};