import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import * as Vue from 'vue';
export default {
  props: ['content', 'hrefs', 'tid', 'urlParams'],
  created() {
    if (this.content.linkType == '0') {
      if (this.content.wholeAnchor != '') {
        this.content.href = '#' + this.hrefs[this.content.wholeAnchor].hrefid;
      }
    } else {
      for (let i = 0; i < this.content.imageList.length; i++) {
        if (this.content.imageList[i].type == '1') {
          this.content.imageList[i].href = this.hrefs[this.content.imageList[i].linkAnchor].hrefid;
        }
      }
    }
  },
  methods: {
    openpage(row) {
      //linkType 0 整图调整  1单图调整
      if (this.content.linkType == '0') {
        if (this.content.wholeType == '0') {
          //链接地址
          if (this.content.wholeUrl != '') {
            var urlParams = {
              ...this.urlParams,
              tid: this.tid
            };
            var url = this.content.wholeUrl;
            for (var k in urlParams) {
              let value = urlParams[k] !== undefined ? urlParams[k] : '';
              url += `${url.indexOf('?') > -1 ? '&' : '?'}${k}=${encodeURIComponent(value)}`;
            }
            window.location.href = url;
          }
        } else if (this.content.wholeType == '2') {
          ///打开弹窗
          if (this.content.popup != '') $emit(this, 'arousePop', this.content.wholePopup);
        } else if (this.content.wholeType == '3') {
          //打开小程序
          $emit(this, 'openWechat', this.content.appletlink, this.content.appletUrl, this.content.wholeApplet, this.content.wholeAppletType);
          // if (this.content.appletlink == 0) {
          //   $emit(
          //     this,
          //     'openWechat',
          //     this.content.wholeApplet,
          //     this.content.wholeAppletType,
          //   )
          // } else {
          //   window.location.href = this.content.appletUrl
          // }
        } else if (this.content.wholeType == '4') {
          $emit(this, 'jumpKf', this.content.wechatKf);
        } else if (this.content.wholeType == '5') {} else if (this.content.wholeType == '6') {
          $emit(this, 'jumpCustomerAcquisition', this.content);
        }
      } else {
        if (row.type == '0') {
          //链接地址
          window.location.href = strategyLists[i].urlLink;
        } else if (row.type == '2') {
          //打开弹窗
          if (row.popup != '') $emit(this, 'arousePop', row.popup);
        } else if (row.type == '3') {
          //打开小程序
          $emit(this, 'openWechat', row.appletlink, row.appletTypeurl, row.applet, row.appletType);

          // if (row.appletlink == 0) {
          //   $emit(this, 'openWechat', row.applet, row.appletType)
          // } else {
          //   window.location.href = row.appletTypeurl
          // }
        } else if (row.type == '4') {
          $emit(this, 'jumpKf', this.row.wechatKf);
        } else if (row.type == '5') {} else if (row.type == '6') {
          $emit(this, 'jumpCustomerAcquisition', this.row);
        }
      }
    }
  },
  emits: ['arousePop', 'openWechat', 'jumpKf']
};