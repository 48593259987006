import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-73b6306b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fixed"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "font-size": "0"
  }
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.content.kefuimg && ($data.baseimg || $data.img) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
    class: "img_wh",
    src: $props.content.kefuimg
  }, null, 8, _hoisted_3), _createElementVNode("div", {
    class: "relative",
    style: _normalizeStyle({
      left: $data.clleft + 'px',
      top: $data.cltop + 'px'
    }),
    onTouchstart: _cache[0] || (_cache[0] = $event => $options.touchStart($event)),
    onTouchend: _cache[1] || (_cache[1] = (...args) => $options.touchend && $options.touchend(...args))
  }, [_createElementVNode("img", {
    class: "qr-code",
    style: _normalizeStyle({
      width: $data.imgsize + 'px'
    }),
    src: !this.pageType && this.modelType === 'ios' ? $data.baseimg : $data.img
  }, null, 12, _hoisted_4)], 36)])) : _createCommentVNode("", true)]);
}