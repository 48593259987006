import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-eeeb6f0c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  key: 0,
  class: "fixed"
};
const _hoisted_3 = {
  key: 1,
  class: "fixed_bottom"
};
const _hoisted_4 = {
  class: "xieyizhezhao"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_circle_close = _resolveComponent("circle-close");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_popup_picture = _resolveComponent("popup-picture");
  const _component_popup_rich = _resolveComponent("popup-rich");
  const _component_popup_agreement = _resolveComponent("popup-agreement");
  const _component_popup_buttom = _resolveComponent("popup-buttom");
  const _component_popup_agreet_content = _resolveComponent("popup-agreet-content");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.bool && $props.popsetting.popStyle == '0' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_withDirectives(_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args)),
    class: "fixed_close"
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_circle_close)]),
    _: 1
  })], 512), [[_vShow, $props.popsetting.closeShow == '0']]), _createElementVNode("div", {
    class: "fixed_wh",
    style: _normalizeStyle({
      backgroundImage: 'url(' + $props.popsetting.bgImg + ')',
      'border-radius': $props.popsetting.boxRadius + 'px',
      'background-color': $props.popsetting.bgColor
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.popcontent, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "list",
      key: 'pop' + index
    }, [item.text == '图片' ? (_openBlock(), _createBlock(_component_popup_picture, {
      key: 0,
      onOpenWechat: $options.openWechat,
      content: item.setStyle
    }, null, 8, ["onOpenWechat", "content"])) : _createCommentVNode("", true), item.text == '富文本' ? (_openBlock(), _createBlock(_component_popup_rich, {
      key: 1,
      content: item.setStyle
    }, null, 8, ["content"])) : _createCommentVNode("", true), item.text == '协议组件' ? (_openBlock(), _createBlock(_component_popup_agreement, {
      key: 2,
      content: item.setStyle,
      onPopxie: $options.popxie
    }, null, 8, ["content", "onPopxie"])) : _createCommentVNode("", true), item.text == '按钮' ? (_openBlock(), _createBlock(_component_popup_buttom, {
      key: 3,
      onOpenWechat: $options.openWechat,
      class: "absolu_bottom",
      content: item.setStyle
    }, null, 8, ["onOpenWechat", "content"])) : _createCommentVNode("", true)]);
  }), 128))], 4)])) : _createCommentVNode("", true), $data.bool && $props.popsetting.popStyle == '1' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_withDirectives(_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = (...args) => $options.close && $options.close(...args)),
    class: "fixed_close"
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_circle_close)]),
    _: 1
  })], 512), [[_vShow, $props.popsetting.closeShow == '0']]), _createElementVNode("div", {
    class: "fixed_bottomwh",
    style: _normalizeStyle({
      backgroundImage: 'url(' + $props.popsetting.bgImg + ')',
      'border-top-left-radius': $props.popsetting.boxRadius + 'px',
      'border-top-right-radius': $props.popsetting.boxRadius + 'px',
      'background-color': $props.popsetting.bgColor
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.popcontent, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "list",
      key: 'pop' + index
    }, [item.text == '图片' ? (_openBlock(), _createBlock(_component_popup_picture, {
      key: 0,
      onOpenWechat: $options.openWechat,
      content: item.setStyle
    }, null, 8, ["onOpenWechat", "content"])) : _createCommentVNode("", true), item.text == '富文本' ? (_openBlock(), _createBlock(_component_popup_rich, {
      key: 1,
      content: item.setStyle
    }, null, 8, ["content"])) : _createCommentVNode("", true), item.text == '协议组件' ? (_openBlock(), _createBlock(_component_popup_agreement, {
      key: 2,
      content: item.setStyle,
      onPopxie: $options.popxie
    }, null, 8, ["content", "onPopxie"])) : _createCommentVNode("", true), item.text == '按钮' ? (_openBlock(), _createBlock(_component_popup_buttom, {
      key: 3,
      onOpenWechat: $options.openWechat,
      onClosetab: $options.close,
      onQure: $options.close,
      class: "absolu_bottom",
      content: item.setStyle
    }, null, 8, ["onOpenWechat", "onClosetab", "onQure", "content"])) : _createCommentVNode("", true)]);
  }), 128))], 4)])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", {
    onClick: _cache[2] || (_cache[2] = (...args) => $options.close && $options.close(...args)),
    class: "zhezhao"
  }, null, 512), [[_vShow, $data.iszhezhao]]), _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [[_vShow, $data.isxieyi]]), $data.isxieyi ? (_openBlock(), _createBlock(_component_popup_agreet_content, {
    key: 2,
    content: $data.agreetContent,
    onCloseisxieyi: $options.closeisxieyi
  }, null, 8, ["content", "onCloseisxieyi"])) : _createCommentVNode("", true)]);
}